<template>
  <div
    class="pt-36 pb-20 bg-yellow-300 grid grid-cols-12"
    :class="{
      'bg-yellow-300': doc === 1,
      'bg-blue-300': doc === 2,
      'bg-pink-300': doc === 5,
    }"
  >
    <div class="hidden md:block col-span-6">
      <div class="mr-0 ml-auto pr-10 mr-6" style="max-width: 600px !important">
        <h1
          class="mt-4 md:max-w-3xl w-full text-left text-xl font-extrabold text-gray-900 sm:mt-5 sm:leading-no leading-none ne sm:text-5xl lg:mt-6 lg:text-3xl xl:text-5xl"
        >
          <span v-if="doc === 1">
            Política Básica de Privacidade (Custo Zero)
          </span>
          <span v-if="doc === 2"> Pacote LGPD Completo </span>
          <span v-if="doc === 5"> Pacote Avançado </span>
        </h1>

        <h2 class="my-3 text-xl text-gray-700" v-if="doc === '1'">
          Política de Privacidade Simples
          <br />
          Ideal para uso pessoal
        </h2>

        <p class="leading-2 text-gray-800 my-4 text-sm">
          Não sabe se precisa dos documentos?
          <a
            href="/artigos/o-que-e-lgpd-e-por-que-sua-empresa-deve-se-adequar"
            class="text-sm underline"
            target="_blank"
            >Ler sobre a importância de estar de acordo com a LGPD</a
          >
        </p>
        <div class="mb-4 flex flex-wrap text-sm font-medium text-black">
          <p class="flex items-center mr-3 mt-3">
            <checkYes />
            Política de Privacidade
          </p>
          <p class="flex items-center mr-3 mt-3">
            <checkYes />

            Versão HTML
          </p>
          <p class="flex items-center mr-3 mt-3">
            <checkYes />

            Versão em Texto
          </p>
          <p class="flex items-center mr-3 mt-3">
            <check-no v-if="doc < 1" />
            <check-yes v-else />
            Termos de Uso
          </p>
          <p class="flex items-center mr-3 mt-3">
            <check-no v-if="doc < 1" />
            <check-yes v-else />
            Necessário para a LGPD
          </p>
          <p class="flex items-center mr-3 mt-3">
            <check-no v-if="doc < 5" />
            <check-yes v-else />
            Relatórios e Guias
          </p>
          <p class="flex items-center mr-3 mt-3">
            <check-no v-if="doc < 5" />
            <check-yes v-else />
            Planilha de Registro de Operações
          </p>
          <p class="flex items-center mr-3 mt-3">
            <check-no v-if="doc < 5" />
            <check-yes v-else />
            Edição Visual de Documentos
          </p>
        </div>

        <div class="" v-if="doc < 5">
          <a
            href="/document/5/politicas-privacidade/MQ=="
            class="btn hover:scale-105 bg-gray-200 duration-150 transition hover:bg-gray-300 mt-6 text-gray-800 rounded-full mr-2 btn-md tracking-tighter px-6 py-3 mx-auto leading-2"
          >
            Precisa de mais termos? Plano completo por R$ 125,00
          </a>
        </div>
        <lottie-animation
          path="img/testa.json"
          class="hidden md:block transform"
          :speed="1"
        />
      </div>
    </div>
    <div
      class="relative px-10 py-5 bg-white rounded-lg shadow-2xl col-span-12 md:col-span-6 lg:col-span-5 xl:col-span-5 ml-0 mr-auro"
      style="max-width: 600px"
    >
      <div id="geradorForm">
        <form
          class="flex grid grid-cols-12 gap-x-6 gap-y-3 pt-6 w-full form-generator validate"
          autocomplete="foo"
          @submit.prevent
        >
          <p class="text-sm col-span-12 font-medium mb-3">
            Formulário de política de privacidade ✍️
          </p>
          <label
            class="text-sm col-span-12 font-medium mb-3"
            :class="{ 'is-filled': formData.cd_email_usuario }"
          >
            <span>Seu e-mail </span>

            <input
              v-model="formData.cd_email_usuario"
              type="text"
              name="email"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
            <small class="text-gray-700"
              >Não iremos fazer spam 🤞 será utilizado para enviar os dados do
              seu documento</small
            >
          </label>

          <label
            class="text-sm col-span-12 font-medium py-6 pb-0 border-t-2 border-gray-200"
            :class="{ 'is-filled': formData.cd_site_url }"
          >
            <span>Endereço do site</span>
            <input
              v-model="formData.cd_site_url"
              id="privacy-address"
              type="url"
              name="url"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
              @focus="handleAddHTTPSiteUrl"
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.cd_email }"
          >
            <span>E-mail</span>

            <input
              v-model="formData.cd_email"
              type="text"
              name="email"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.cd_cnpj }"
          >
            <span>CNPJ</span>

            <TheMask
              v-model="formData.cd_cnpj"
              type="text"
              name="cnpj"
              class="form-input rounded-sm mt-1 border-gray-300 text-xs"
              :mask="['##.###.###/####-##']"
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.nm_rz_social }"
          >
            <span>Nome da Empresa</span>

            <input
              v-model="formData.nm_rz_social"
              type="text"
              name="razao_social"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="text-sm col-span-6 font-medium"
            :class="{ 'is-filled': formData.nm_fantasia }"
          >
            <span>Nome Fantasia</span>

            <input
              v-model="formData.nm_fantasia"
              type="text"
              name="name_fatansia"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="col-span-4 text-sm font-medium"
            :class="{ 'is-filled': formData.cd_endereco_cep }"
          >
            <span>CEP</span>
            <TheMask
              v-model="formData.cd_endereco_cep"
              type="text"
              name="cep"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
              :mask="['##.###-###']"
            />
          </label>
          <label
            class="col-span-4 text-sm font-medium"
            :class="{ 'is-filled': formData.nm_endereco_cidade }"
            ><span>Cidade</span>
            <input
              v-model="formData.nm_endereco_cidade"
              type="text"
              name="cidade"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
              disabled
            />
          </label>
          <label
            class="col-span-4 text-sm font-medium"
            :class="{ 'is-filled': formData.nm_endereco_bairro }"
          >
            <span>Bairro</span>

            <input
              v-model="formData.nm_endereco_bairro"
              type="text"
              name="bairro"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
              disabled
            />
          </label>
          <label
            class="col-span-4 text-sm font-medium"
            :class="{ 'is-filled': formData.nm_endereco_logradouro }"
          >
            <span>Logradouro</span>

            <input
              v-model="formData.nm_endereco_logradouro"
              type="text"
              name="logradouro"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
              disabled
            />
          </label>
          <label
            class="col-span-4 text-sm font-medium"
            :class="{ 'is-filled': formData.cd_endereco_numero }"
          >
            <span>Número</span>

            <input
              v-model="formData.cd_endereco_numero"
              type="text"
              name="endereco_numero"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="col-span-4 text-sm font-medium"
            :class="{ 'is-filled': formData.nm_endereco_complemento }"
          >
            <span>Complemento</span>

            <input
              v-model="formData.nm_endereco_complemento"
              type="text"
              name="complemento"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="col-span-6 text-sm font-medium"
            :class="{ 'is-filled': formData.nm_atividade_principal }"
          >
            <span>Atividade Principal</span>

            <input
              v-model="formData.nm_atividade_principal"
              id="atividade_principal"
              type="text"
              name="atividade_principal"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <label
            class="col-span-6 text-sm font-medium"
            :class="{ 'is-filled': formData.ds_atividade_principal }"
          >
            <span>Descrição Atividade Principal</span>

            <input
              v-model="formData.ds_atividade_principal"
              id="descricao_atividade"
              type="text"
              name="descricao_atividade"
              class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
            />
          </label>
          <div class="col-span-12 pb-3"></div>
          <h3
            class="text-lg font-medium col-span-12 py-6 pb-0 border-t-2 border-gray-200"
          >
            Customizar política da empresa (política de privacidade)
          </h3>
          <div class="comprehensive-options col-span-12">
            <fieldset>
              <div class="col-span-12 my-3">
                <label
                  class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                >
                  <input
                    v-model="formData.is_coletado_historico"
                    type="checkbox"
                    name="options[]"
                    class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                    value="logs"
                  />
                  <span>
                    <span class="block font-medium text-black">Histórico</span>
                    Registro os dados de navegador e visitante, mas não os
                    identifico diretamente.
                  </span>
                </label>
              </div>
              <div class="col-span-12 my-3">
                <label
                  class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                >
                  <input
                    v-model="formData.is_coletado_dados_dispositivo"
                    type="checkbox"
                    class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                    name="options[]"
                    value="collects-device-data"
                  />
                  <span class="checkbox"></span>
                  <span>
                    <span class="block font-medium text-black"
                      >Dados do dispositivo</span
                    >
                    Você coleta dados do dispositivo e/ou informações de GPS.
                  </span>
                </label>
              </div>
              <div class="col-span-12 my-3">
                <label
                  class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                >
                  <input
                    v-model="formData.is_coletado_dados_pessoais"
                    type="checkbox"
                    class="toggle form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                    name="options[]"
                  />
                  <span class="checkbox"></span>
                  <span>
                    <span class="block font-medium text-black"
                      >Dados Pessoais</span
                    >
                    Você solicita dados pessoais, junto com as informações que
                    você coleta.
                  </span>
                </label>
                <div
                  class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                  :class="{
                    'u-hide': !formData.is_coletado_dados_pessoais,
                  }"
                >
                  <div class="sub">
                    <p class="my-5 text-md font-medium text-gray-800 text-sm">
                      <span class="block font-medium text-black">
                        Tipos de dados pessoais que você pede:
                      </span>
                    </p>
                    <ul class="grid grid-cols-2">
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_coletado_nome"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[collects-personal-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Nome</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_coletado_email"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[collects-personal-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Email</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_coletado_midias_sociais"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[collects-personal-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Perfil de mídias sociais</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_coletado_dt_nascimento"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[collects-personal-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Data de nascimento</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_coletado_telefone"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[collects-personal-info][]"
                            value="phone"
                          />
                          <span class="checkbox"></span>
                          <span>Número de telefone/celular</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_coletado_endereco_residencial"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[collects-personal-info][]"
                            value="home-address"
                          />
                          <span class="checkbox"></span>
                          <span>Endereço residencial</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_coletado_endereco_comercial"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[collects-personal-info][]"
                            value="work-address"
                          />
                          <span class="checkbox"></span>
                          <span>Endereço comercial</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_coletado_info_pagamento"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[collects-personal-info][]"
                            value="payment"
                          />
                          <span class="checkbox"></span>
                          <span>Informações de pagamento</span>
                        </label>
                      </li>
                    </ul>
                    <p class="my-5 text-md font-medium text-gray-800 text-sm">
                      <span class="block font-medium text-black">
                        Para quais finalidades você tratar os dados pessoais
                        solicitados:
                      </span>
                    </p>
                    <ul class="grid grid-cols-2">
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_utilizado_ux"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[use-of-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Customizar a experiência do usuário</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_utilizado_acesso"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[use-of-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Garantir o acesso ao site</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_utilizado_contatar"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[use-of-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Contatar os usuários</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_utilizado_manu_adm"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[use-of-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Manutenção e administração de registros</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_utilizado_analytics"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[use-of-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Analytics e pesquisa de mercado</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_utilizado_concursos"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[use-of-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Concursos e promoções</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_utilizado_pubi_mark"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[use-of-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Publicidade e marketing</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_utilizado_compliance"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[use-of-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Compliance &amp; solução de conflitos</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_utilizado_recrutamento"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[use-of-info][]"
                          />
                          <span class="checkbox"></span>
                          <span>Recrutamento e seleção de pessoas</span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-span-12 my-3">
                <label
                  class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                >
                  <input
                    v-model="formData.is_coletado_dados_gerados"
                    type="checkbox"
                    class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                    name="options[]"
                    value="publish-ugc"
                  />
                  <span class="checkbox"></span>
                  <span>
                    <span class="block font-medium text-black"
                      >Conteúdo gerado pelo usuário</span
                    >
                    Você coleta e publica conteúdo gerado pelo usuário.
                  </span>
                </label>
              </div>
              <div class="col-span-12 my-3">
                <label
                  class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                >
                  <input
                    v-model="formData.is_compartilhado_dados"
                    type="checkbox"
                    class="toggle form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                    name="options[]"
                  />
                  <span class="checkbox"></span>
                  <span>
                    <span class="block font-medium text-black"
                      >Compartilhamento dos dados</span
                    >
                    Você contrata serviços de terceiros para o seu site e / ou
                    atividade de marketing.
                  </span>
                </label>
                <div
                  class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                  :class="{ 'u-hide': !formData.is_compartilhado_dados }"
                >
                  <div class="sub">
                    <p class="my-5 text-md font-medium text-gray-800 text-sm">
                      <span class="block font-medium text-black">
                        A quais terceiros você divulga dados pessoais?
                      </span>
                    </p>
                    <ul class="grid grid-cols-2">
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_compartilhado_plat_dados"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[third-party-services][]"
                          />
                          <span class="checkbox"></span>
                          <span> Plataforma de Dados </span>
                        </label>
                      </li>

                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_compartilhado_combate_fraudes"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[third-party-services][]"
                          />
                          <span class="checkbox"></span>
                          <span>Parceiros de combate a fraudes</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.is_compartilhado_parceiro"
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[third-party-services][]"
                          />
                          <span class="checkbox"></span>
                          <span>Parceiros de nossos clientes</span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="
                              formData.is_compartilhado_autoridade_oficiais
                            "
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[third-party-services][]"
                          />
                          <span class="checkbox"></span>
                          <span>Autoridades públicas ou órgãos oficiais</span>
                        </label>
                      </li>
                      <li class="my-2 col-span-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="
                              formData.is_compartilhado_conferencia_indentificadores
                            "
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[third-party-services][]"
                          />
                          <span class="checkbox"></span>
                          <span>
                            Parceiros de conferência de identificadores
                          </span>
                        </label>
                      </li>
                    </ul>
                    <p class="my-5 text-md font-medium text-gray-800 text-sm">
                      <span class="block font-medium text-black">
                        Considerações sobre o compartilhamento de dados:
                      </span>
                    </p>
                    <ul class="grid grid-cols-2">
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="
                              formData.is_compartilhado_servicos_cookies_terceiros
                            "
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[third-party-considerations][]"
                            value="cookies"
                          />
                          <span class="checkbox"></span>
                          <span>
                            Os serviços de terceiros em seu site exigem cookies
                            para funcionar corretamente
                          </span>
                        </label>
                      </li>
                      <li class="my-2">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="
                              formData.is_compartilhado_servicos_politicas_terceiros
                            "
                            type="checkbox"
                            class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                            name="dictionary[third-party-considerations][]"
                            value="respect"
                          />
                          <span class="checkbox"></span>
                          <span>
                            Você só adere a serviços de terceiros cujas
                            políticas de privacidade estejam alinhadas com a
                            sua.
                          </span>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-span-12 my-3">
                <div class="col-span-12 my-3">
                  <label
                    class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                  >
                    <input
                      v-model="formData.is_utilizado_cookie"
                      type="checkbox"
                      class="toggle form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                      name="options[]"
                      value="cookies"
                    />
                    <span class="checkbox"></span>
                    <span>
                      <span class="block font-medium text-black">Cookies</span>
                      Adicione uma Política de Cookies à sua Política de
                      Privacidade.
                    </span>
                  </label>
                  <div
                    class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                    :class="{ 'u-hide': !formData.is_utilizado_cookie }"
                  >
                    <div class="sub">
                      <p class="my-5 text-md font-medium text-gray-800 text-sm">
                        <span class="block font-medium text-black"
                          >Tipos de cookies utilizados:</span
                        >
                      </p>
                      <ul class="grid grid-cols-2">
                        <li class="my-2">
                          <label
                            class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                          >
                            <input
                              v-model="formData.is_utilizado_cookie_necessarios"
                              type="checkbox"
                              class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                              name="dictionary[cookies][]"
                              value="essential"
                            />
                            <span class="checkbox"></span>
                            <span>
                              <span class="block font-medium text-black"
                                >Cookies Necessários</span
                              >
                              por exemplo, logins de usuários, gerenciamento de
                              contas...
                            </span>
                          </label>
                        </li>
                        <li class="my-2">
                          <label
                            class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                          >
                            <input
                              v-model="formData.is_utilizado_cookie_desempenho"
                              type="checkbox"
                              class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                              name="dictionary[cookies][]"
                              value="performance"
                            />
                            <span class="checkbox"></span>
                            <span>
                              <span class="block font-medium text-black"
                                >Cookies de desempenho</span
                              >
                              por exemplo, analytics, rastreamento de
                              visitantes...
                            </span>
                          </label>
                        </li>
                        <li class="my-2">
                          <label
                            class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                          >
                            <input
                              v-model="formData.is_utilizado_cookie_auth"
                              type="checkbox"
                              class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                              name="dictionary[cookies][]"
                              value="functionality"
                            />
                            <span class="checkbox"></span>
                            <span>
                              <span class="block font-medium text-black"
                                >Cookies de Autenticação</span
                              >
                              servem para reconhecer um usuário e proporcionar
                              experiências de navegação personalizadas
                            </span>
                          </label>
                        </li>
                        <li class="my-2">
                          <label
                            class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                          >
                            <input
                              v-model="
                                formData.is_utilizado_cookie_rede_sociais
                              "
                              type="checkbox"
                              class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                              name="dictionary[cookies][]"
                              value="functionality"
                            />
                            <span class="checkbox"></span>
                            <span>
                              <span class="block font-medium text-black"
                                >Cookies de Redes Sociais</span
                              >
                              permitem a interação do usuário com redes sociais
                              por meio de nossa página
                            </span>
                          </label>
                        </li>
                        <li class="my-2">
                          <label
                            class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                          >
                            <input
                              v-model="formData.is_utilizado_cookie_marketing"
                              type="checkbox"
                              class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                              name="dictionary[cookies][]"
                              value="advertising"
                            />
                            <span class="checkbox"></span>
                            <span>
                              <span class="block font-medium text-black"
                                >Cookies de Marketing</span
                              >
                              por exemplo, conteúdo de parceiros, anúncios
                              pop-up...
                            </span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div class="col-span-12 my-3"></div>

                <div class="col-span-12 my-3">
                  <label
                    class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                  >
                    <input
                      v-model="formData.is_protegido_dados"
                      type="checkbox"
                      class="toggle form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                      name="options[]"
                    />
                    <span class="checkbox"></span>
                    <span>
                      <span class="block font-medium text-black"
                        >Proteção de dados</span
                      >
                    </span>
                  </label>
                  <div
                    class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                    :class="{ 'u-hide': !formData.is_protegido_dados }"
                  >
                    <div class="sub">
                      <p class="my-5 text-md font-medium text-gray-800 text-sm">
                        <span class="block font-medium text-black">
                          Quais meios você utiliza para proteger os dados ?
                        </span>
                      </p>
                      <ul class="grid grid-cols-2">
                        <li class="my-2">
                          <label
                            class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                          >
                            <input
                              v-model="formData.is_protegido_politica_interna"
                              type="checkbox"
                              class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                              name="dictionary[third-party-services][]"
                            />
                            <span class="checkbox"></span>
                            <span> Politica Interna </span>
                          </label>
                        </li>
                        <li class="my-2">
                          <label
                            class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                          >
                            <input
                              v-model="formData.is_protegido_plano_resposta"
                              type="checkbox"
                              class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                              name="dictionary[third-party-services][]"
                            />
                            <span class="checkbox"></span>
                            <span> Plano Resposta </span>
                          </label>
                        </li>
                        <li class="my-2">
                          <label
                            class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                          >
                            <input
                              v-model="formData.is_protegido_ssl"
                              type="checkbox"
                              class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                              name="dictionary[third-party-services][]"
                            />
                            <span class="checkbox"></span>
                            <span>Utilizamos SSL</span>
                          </label>
                        </li>
                        <li class="my-2">
                          <label
                            class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                          >
                            <input
                              v-model="formData.is_protegido_treinamento"
                              type="checkbox"
                              class="form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                              name="dictionary[third-party-services][]"
                            />
                            <span class="checkbox"></span>
                            <span>Treinamento</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="col-span-12 my-3"></div>
                <div class="col-span-12 my-3">
                  <label
                    class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                  >
                    <input
                      v-model="formData.is_controlador_dados"
                      type="checkbox"
                      class="toggle form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                      name="options[]"
                      value="controlador-dados"
                    />
                    <span class="checkbox"></span>
                    <span>
                      <span class="block font-medium text-black">
                        Detalhes de contato do controlador dos dados
                      </span>
                      O controlador dos dados é aquele que decide e determina
                      quais dados pessoais devem ser tratados e para quais
                      finalidades.
                    </span>
                  </label>
                  <div
                    class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                    :class="{ 'u-hide': !formData.is_controlador_dados }"
                  >
                    <div class="grid-cols-2">
                      <p class="col-span-2 text-sm my-3">
                        Insira abaixo o nome da empresa ou pessoa Controladora
                        dos dados e o meio de contato preferido
                      </p>
                      <div class="col-span-12 my-3">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.nm_controlador_dados"
                            type="text"
                            name="dictionary[data-controller-name]"
                            class="required-depends-on form-input rounded-sm mt-1 border-gray-300"
                            data-dependency=".comprehensive-options .dc-checkbox"
                            placeholder="Nome do Controlador de dados"
                          />
                        </label>
                      </div>
                      <div class="col-span-12 my-3">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.cd_contato_controlador_dados"
                            type="text"
                            name="dictionary[data-controller-email]"
                            class="required-depends-on form-input rounded-sm mt-1 border-gray-300"
                            data-dependency=".comprehensive-options .dc-checkbox"
                            placeholder="Contato do Controlador de dados (site ou e-mail)"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-span-12 my-3">
                  <label
                    class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                  >
                    <input
                      v-model="formData.is_dpo"
                      type="checkbox"
                      class="toggle dpo-checkbox form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                      name="options[]"
                      value="dpo"
                    />
                    <span class="checkbox"></span>
                    <span>
                      <span class="block font-medium text-black"
                        >Encarregado dos dados ou DPO</span
                      >
                      O encarregado dos dados é a pessoa indicada pelo
                      Controlador para mediar a comunicação entre os titulares
                      dos dados, o controlador e a Autoridade Nacional de
                      Proteção de Dados.
                    </span>
                  </label>
                  <div
                    class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                    :class="{ 'u-hide': !formData.is_dpo }"
                  >
                    <div class="sub">
                      <p class="my-3 text-sm">
                        Insira abaixo o contato do encarregado. (Atenção: esta
                        informação é obrigatória conforme o artigo 41 da LGPD).
                      </p>
                      <div class="col-span-12 my-3">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.nm_dpo"
                            type="text"
                            name="dictionary[dpo-name]"
                            class="required-depends-on form-input rounded-sm mt-1 border-gray-300"
                            data-dependency=".comprehensive-options .dpo-checkbox"
                            placeholder="Nome do DPO"
                          />
                        </label>
                      </div>
                      <div class="col-span-12 my-3">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.cd_email_dpo"
                            type="text"
                            name="dictionary[dpo-email]"
                            class="required-depends-on form-input rounded-sm mt-1 border-gray-300"
                            data-dependency=".comprehensive-options .dpo-checkbox"
                            placeholder="E-mail do DPO"
                          />
                        </label>
                      </div>
                      <div class="col-span-12 my-3">
                        <label
                          class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                        >
                          <input
                            v-model="formData.cd_telefone_dpo"
                            type="text"
                            name="dictionary[dpo-contato]"
                            class="required-depends-on form-input rounded-sm mt-1 border-gray-300"
                            data-dependency=".comprehensive-options .dpo-checkbox"
                            placeholder="Contato do DPO"
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-span-12 my-3">
                  <label
                    class="text-sm text-gray-800 flex items-center mt-0 pt-0"
                  >
                    <input
                      v-model="formData.is_nova_clausula"
                      type="checkbox"
                      class="toggle dpo-checkbox form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
                      name="options[]"
                      value="dpo"
                    />
                    <span class="checkbox"></span>
                    <span>
                      <span class="block font-medium text-black">
                        Adicionar sua própria cláusula na Política de Privacidade
                      </span>
                      Selecione caso deseja adicionar uma cláusula na Política de Privacidade
                    </span>
                  </label>
                  <div
                    class="toggle-target border-b-2 border-t-2 border-gray-200 py-0 pb-3 rounded-lg my-6 bg-gray-100 p-3 border-2"
                    :class="{ 'u-hide': !formData.is_nova_clausula }"
                  >
                    <div class="sub">
                      <div class="col-span-12 my-3">
                        <label
                          class="text-sm col-span-12 font-medium"
                          for="nome_nova_clausula"
                          :class="{ 'is-filled': formData.nm_nova_clausula }"
                        >
                          <input
                            v-model.trim="formData.nm_nova_clausula"
                            id="nome_nova_clausula"
                            type="text"
                            class="required form-input rounded-sm mt-1 border-gray-300 text-xs"
                            placeholder="Título da clausula"
                            maxlength="100"
                          />
                        </label>

                        <textarea
                          v-model.trim="formData.ds_nova_clausula"
                          type="text"
                          class="required-depends-on w-100 text-xs p-3 mt-2"
                          placeholder="Escreva sua cláusula"
                          style="
                          width: 100%;
                          min-height: 100px;
                          max-height: 200px;
                        "
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </fieldset>
          </div>

          <div class="col-span-12" v-if="doc > 1">
            <p class="text-xs text-left my-6 text-gray-700">
              Como a easy termos oferece compras únicas com custo baixo, no
              momento não podemos oferecer edição de políticas. Se você não
              tiver certeza se deve incluir determinados detalhes em sua
              política ao preencher o formulário, recomendamos incluí-los de
              qualquer maneira. Isso evita que você tenha que voltar e fazer
              outra compra se precisar alterar alguma coisa.
            </p>
          </div>

          <div class="text-xs text-gray-500 col-span-12">
            O software, os materiais e a assistência fornecidos pela Easy Termos
            têm como único objetivo auxiliar os usuários no cumprimento de seus
            requisitos legais. Em particular, os modelos fornecidos são gerados
            automaticamente, mas cada palavra de nosso modelo foi escrita e
            continuamente revisada por uma equipe jurídica qualificada. No
            entanto, como pode ser facilmente compreendido, nada pode substituir
            uma consultoria jurídica profissional na elaboração da sua política
            de privacidade, política de cookies ou de qualquer outro documento
            legal ou procedimento de compliance.
          </div>

          <label
            class="text-sm text-gray-800 flex items-center mt-0 pt-0 col-span-12 mb-6"
          >
            <input
              class="toggle controlador-dados form-checkbox h-8 w-8 rounded text-et-500 shadow mr-4 pb-2"
              name="options[]"
              type="checkbox"
              v-model="formData.is_concordo_isencao_easy"
              required
            />
            <span class="checkbox"></span>
            <span class="block font-medium text-black">
              <strong> Eu concordo com o aviso legal da Easy Termos </strong>
            </span>
          </label>

          <button
            @click="validateFormTerms"
            type="submit"
            class="col-span-12 bg-et-500 hover:bg-indigo-500 transition duration-300 text-white font-bold px-4 rounded w-full py-5"
          >
            {{ buttonText }}
            <span v-if="doc === 2" class="font-medium">- R$ 90.00</span>
            <span v-if="doc === 5" class="font-medium">- R$ 125.00</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import { mapActions, mapGetters, mapState } from 'vuex';
import { functions } from '@/utils/functions';
import AddressService from '@/services/addressService';
import CheckYes from '@/components/DocumentsForm/CheckYes';
import CheckNo from '@/components/DocumentsForm/CheckNo';
import LottieAnimation from 'lottie-vuejs/src/LottieAnimation.vue'; // import lottie-vuejs

export default {
  name: 'FormPoliticaPrivacidade',
  status: 'prototype',
  release: '1.0.0',
  mixins: [functions],
  components: {
    CheckNo,
    CheckYes,
    TheMask,
    LottieAnimation,
  },
  props: {
    doc: {
      type: Number,
      required: false,
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Atualizar seus Termos',
    },
  },
  data() {
    return {
      formData: {
        cd_email_usuario: '',
        is_concordo_isencao_easy: false,
        cd_site_url: '',
        cd_email: '',
        nm_rz_social: '',
        nm_fantasia: '',
        cd_cnpj: '',
        nm_endereco_logradouro: '',
        cd_endereco_numero: '',
        nm_endereco_complemento: '',
        nm_endereco_bairro: '',
        nm_endereco_cidade: '',
        cd_endereco_cep: '',
        nm_atividade_principal: '',
        ds_atividade_principal: '',
        is_coletado_historico: false,
        is_coletado_dados_dispositivo: false,
        is_coletado_dados_gerados: false,
        is_coletado_dados_pessoais: false,
        is_coletado_nome: false,
        is_coletado_dt_nascimento: false,
        is_coletado_endereco_comercial: false,
        is_coletado_email: false,
        is_coletado_telefone: false,
        is_coletado_info_pagamento: false,
        is_coletado_midias_sociais: false,
        is_coletado_endereco_residencial: false,
        is_utilizado_ux: false,
        is_utilizado_manu_adm: false,
        is_utilizado_pubi_mark: false,
        is_utilizado_acesso: false,
        is_utilizado_analytics: false,
        is_utilizado_compliance: false,
        is_utilizado_contatar: false,
        is_utilizado_concursos: false,
        is_utilizado_recrutamento: false,
        is_utilizado_cookie: false,
        is_utilizado_cookie_necessarios: false,
        is_utilizado_cookie_desempenho: false,
        is_utilizado_cookie_auth: false,
        is_utilizado_cookie_rede_sociais: false,
        is_utilizado_cookie_marketing: false,
        is_compartilhado_dados: false,
        is_compartilhado_plat_dados: false,
        is_compartilhado_conferencia_indentificadores: false,
        is_compartilhado_combate_fraudes: false,
        is_compartilhado_parceiro: false,
        is_compartilhado_autoridade_oficiais: false,
        is_compartilhado_servicos_cookies_terceiros: false,
        is_compartilhado_servicos_politicas_terceiros: false,
        is_protegido_dados: false,
        is_protegido_politica_interna: false,
        is_protegido_plano_resposta: false,
        is_protegido_ssl: false,
        is_protegido_treinamento: false,
        is_controlador_dados: false,
        nm_controlador_dados: '',
        cd_contato_controlador_dados: '',
        is_dpo: false,
        nm_dpo: '',
        cd_email_dpo: '',
        cd_telefone_dpo: '',
        is_nova_clausula: false,
        nm_nova_clausula: '',
        ds_nova_clausula: ''
      },
    };
  },
  watch: {
    async 'formData.cd_endereco_cep'() {
      const { cd_endereco_cep } = this.formData;

      if (cd_endereco_cep.length === 8) {
        let response = await AddressService.getAddress(cd_endereco_cep);

        if (!response.erro) {
          this.formData.nm_endereco_cidade = response.localidade;
          this.formData.nm_endereco_logradouro = response.logradouro;
          this.formData.nm_endereco_bairro = response.bairro;

          if (!this.formData.nm_endereco_complemento) {
            this.formData.nm_endereco_complemento = response.complemento;
          }
        }
      }
    },
  },
  created() {
    this.formData = { ...this.formData, ...this.document.ds_json_props };
  },
  computed: {
    ...mapGetters('alert', ['errorMessages', 'successMessages']),
    ...mapGetters('auth', ['isLoggedIn']),
    ...mapState('auth', ['user', 'loading']),
    ...mapState('document', ['document']),
  },
  methods: {
    ...mapActions('modal', ['toggleModalLogin', 'toggleIsCreatingDocument']),
    ...mapActions('document', ['registerDocument']),
    ...mapActions('alert', ['success', 'info', 'error']),
    /**
     * Responsável por já iniciar a URL com o HTTP
     */
    handleAddHTTPSiteUrl() {
      if (!this.formData.cd_site_url) {
        this.formData.cd_site_url = `http://${this.formData.cd_site_url}`;
      }
    },
    validateFormTerms() {
      if (
        !this.formData.cd_site_url ||
        this.formData.cd_site_url == 'http://'
      ) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher o endereço do site',
        });

        return;
      }

      if (!this.formData.cd_email) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher o endereço de e-mail',
        });

        return;
      }

      if (!this.formData.nm_rz_social) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher o nome da empresa',
        });

        return;
      }

      if (!this.formData.nm_fantasia) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher o nome fantasia',
        });

        return;
      }

      if (!this.formData.cd_endereco_cep) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher o CEP',
        });

        return;
      }

      if (!this.formData.nm_atividade_principal) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher qual é a sua atividade principal',
        });

        return;
      }

      if (!this.formData.ds_atividade_principal) {
        this.error({
          showType: 'toast',
          text: 'Você precisa preencher a descrição da sua atividade principal',
        });

        return;
      }

      if (this.formData.is_controlador_dados) {
        if (!this.formData.nm_controlador_dados) {
          this.error({
            showType: 'toast',
            text: 'Você precisa preencher o nome do controlador de dados',
          });
          return;
        }

        if (!this.formData.cd_contato_controlador_dados) {
          this.error({
            showType: 'toast',
            text: 'Você precisa preencher o contato do controlador de dados',
          });
          return;
        }
      }

      if (this.formData.is_dpo) {
        if (!this.formData.nm_dpo) {
          this.error({
            showType: 'toast',
            text: 'Você precisa preencher o nome do DPO',
          });
          return;
        }

        if (!this.formData.cd_email_dpo) {
          this.error({
            showType: 'toast',
            text: 'Você precisa preencher o e-mail do DPO',
          });
          return;
        }

        if (!this.formData.cd_telefone_dpo) {
          this.error({
            showType: 'toast',
            text: 'Você precisa preencher o contato do DPO',
          });
          return;
        }
      }

      if(this.formData.is_nova_clausula){
        if(!this.formData.nm_nova_clausula){
          this.error({
            showType: 'toast',
            text: 'Você precisa preencher o título da nova cláusula',
          });
          return;
        }

        if(!this.formData.ds_nova_clausula){
          this.error({
            showType: 'toast',
            text: 'Você precisa preencher a nova cláusula',
          });
          return;
        }
      }

      if (!this.formData.is_concordo_isencao_easy) {
        this.error({
          showType: 'toast',
          text:
            'Você precisa concordar com a isenção de responsabilidade da EasyTermos',
        });

        return;
      }

      this.submitForm();
    },
    submitForm() {
      this.$emit('submit-form', this.formData);
    },
  },
};
</script>
